<template>
	<div v-if="enableBalloons" class="balloons">
        <div v-for="(b, index) in balloons" :key="index" class="balloon" :style="`--x: ${b.x}; --h: ${b.h}; --s: ${b.s}; --d: ${b.d}; --delay: ${b.delay}`">
			<div class="balloon__handle"></div>
		</div>
	</div>
</template>

<script>
import { mapState } from 'vuex'
import '@/mixins/date'

export default {
    name: 'Birthday',
    data() {
        return {
            enableBalloons: false,
            balloons: [
                { x: 68, h: 357, s: 27, d: 9, delay: 6 },
                { x: 39, h: 273, s: 17, d: 3, delay: 4 },
                { x: 20, h: 68,  s: 16, d: 4, delay: 7 },
                { x: 76, h: 84,  s: 16, d: 8, delay: 4 },
                { x: 98, h: 2,   s: 24, d: 8, delay: 9 },
                { x: 48, h: 349, s: 45, d: 2, delay: 5 },
                { x: 51, h: 111, s: 41, d: 8, delay: 9 },
                { x: 55, h: 76,  s: 21, d: 8, delay: 0 },
                { x: 48, h: 127, s: 48, d: 9, delay: 5 },
                { x: 37, h: 287, s: 28, d: 5, delay: 6 },
                { x: 68, h: 258, s: 35, d: 6, delay: 2 },
                { x: 72, h: 287, s: 32, d: 5, delay: 1 },
                { x: 74, h: 155, s: 48, d: 6, delay: 2 },
                { x: 81, h: 98,  s: 21, d: 9, delay: 3 },
                { x: 25, h: 161, s: 37, d: 2, delay: 6 },
                { x: 75, h: 185, s: 42, d: 4, delay: 3 },
                { x: 88, h: 108, s: 21, d: 5, delay: 0 },
                { x: 26, h: 193, s: 36, d: 6, delay: 4 },
                { x: 15, h: 321, s: 29, d: 9, delay: 5 },
                { x: 79, h: 165, s: 34, d: 9, delay: 6 },
                { x: 25, h: 0,   s: 24, d: 8, delay: 9 },
                { x: 87, h: 24,  s: 45, d: 4, delay: 8 },
                { x: 57, h: 89,  s: 32, d: 4, delay: 4 },
                { x: 56, h: 130, s: 43, d: 4, delay: 2 },
                { x: 42, h: 55,  s: 49, d: 3, delay: 8 },
                { x: 65, h: 292, s: 40, d: 7, delay: 8 },
                { x: 37, h: 19,  s: 32, d: 4, delay: 4 }
            ]
        }
    },
    mounted() {
        this.checkBirthday()
    },
    methods: {
        checkBirthday() {
            if (this.user && this.user.birth_date) {
                const birthdate = this.today(this.user.birth_date)
                let yearBirthdayLocal = localStorage.getItem('@stup:birthday')

                if (!yearBirthdayLocal || yearBirthdayLocal != this.year()) {
                    if (this.today() == birthdate) {
                        localStorage.setItem('@stup:birthday', this.year())
                        this.enableBalloons = true
                        setTimeout(() => {
                            this.enableBalloons = false
                        }, 5000)
                    }
                }
            }
        }
    },
    watch: {
        user(v) {
            if (v) {
                this.checkBirthday()
            }
        }
    },
    computed: {
        ...mapState('user', ['user'])
    }
}
</script>
