<template>
	<article class="column is-12-mobile is-half-desktop">
		<h3 class="is-size-5 has-text-primary pl-5">{{ $tc('labels.lastest', users.length) }} <strong>{{ $tc('menu.register', users.length)}}</strong></h3>
		<ul class="dashboard__panel dashboard__list">
			<li class="dashboard__list__item" v-for="u in users" :key="u.id">
				<span class="dashboard__list__name">
					<span class="is-ellipsis is-capitalized is-semibold">{{ u.full_name }}</span>
					<small class="is-ellipsis is-block">{{ u.email }}</small>
				</span>
				<span class="dashboard__list__date">{{ format(u.created_at) }}</span>
			</li>
		</ul>
	</article>
</template>

<script>
import Api from '@/services/api'
import '@/mixins/date'

export default {
	name: 'Users',
	data() {
		return {
			users: []
		}
	},
	mounted() {
		this.getUsers()
	},
	methods: {
		getUsers() {
			Api.post('users/filterByOrder', { name: 3, status: 1, rows: 6 })
				.then(({ data }) => {
					this.users = data
				})
				.catch((err) => {
					console.log(err)
				})
		}
	}
}
</script>
