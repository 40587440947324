<template>
	<Layout>
		<Birthday />
		<Header :bordered="false" />
		<div class="dashboard">
			<b-loading :is-full-page="false" v-model="loading"></b-loading>
			<b-carousel-list v-if="overview.length > 0" animated="slide" :data="overview" :has-drag="true" :items-to-show="isDesktop ? 5 : isTablet ? 4 : 2">
				<template #item="overview">
					<div class="dashboard__box px-2 pt-3 pb-5">
						<h4 class="dashboard__box__value">
							{{ formatNumber(overview.value) }}
						</h4>
						<span v-if="overview.type.charAt(0) == '$'" class="dashboard__box__type">{{ $tc('menu.roles', [overview.type.length]) }}</span>
						<span v-else class="dashboard__box__type">{{ overview.type }}</span>
					</div>
				</template>
			</b-carousel-list>
			<span v-else class="dashboard__dashboard">
				<b-skeleton v-for="i in 5" :key="i" width="250" height="120" class="dashboard__box"></b-skeleton>
			</span>
			<section class="columns is-multiline is-mobile mt-3" v-if="user && user.role.name == 'master'">
				<Users />
				<article class="column is-12-mobile is-half-desktop">
					<h3 class="is-size-5 has-text-primary pl-5">
						<span v-html="$t('labels.availability_areas')"></span>
					</h3>
					<div class="dashboard__panel" v-if="overview.length > 0">
						<doughnut-chart class="dashboard__chart" :chart-data="dataCollection" />
					</div>
				</article>
			</section>
			<section class="columns is-multiline is-mobile mt-3" v-if="user && user.role.name == 'producer'">
				<div ref="map-root" class="land-map"></div>
			</section>
		</div>
	</Layout>
</template>

<script>
import Layout from '@/layouts/Default'
import Header from '@/components/Header'
import DoughnutChart from '@/components/charts/DoughnutChart.js'
import Users from '@/components/Users.vue'
import Api from '@/services/api'
import Birthday from '@/components/Birthday'
import { responsive } from '@/mixins/responsive'
import { mapState } from 'vuex'
import '@/mixins/generic'
import validator from 'validator'
import View from 'ol/View'
import Map from 'ol/Map'
import TileLayer from 'ol/layer/Tile'
import OSM from 'ol/source/OSM'
import VectorLayer from 'ol/layer/Vector'
import VectorSource from 'ol/source/Vector'
import Point from 'ol/geom/Point'
import { fromLonLat } from 'ol/proj'
import Style from 'ol/style/Style'
import Feature from 'ol/Feature'
import Icon from 'ol/style/Icon'
import 'ol/ol.css'

export default {
	name: 'Dashboard',
	mixins: [responsive],
	components: {
		Layout,
		Header,
		Users,
		DoughnutChart,
		Birthday
	},
	data() {
		return {
			loading: false,
			bordered: true,
			dataCollection: null,
			overview: [],
			lands: [],
			map: null
		}
	},
	mounted() {
		this.getOverview()
		if (this.user && this.user.role.name == 'producer') {
			this.getLands()
		}
	},
	methods: {
		fillChartData() {
			let labelsChart = [], dataChart = []

			this.overview.forEach((o) => {
				if (['Hectares', 'CO2'].includes(o.type)) {
					let value = o.type == 'Hectares' ? (o.value * 200) : o.value
					labelsChart.push(`${o.type} (${value})`)
					dataChart.push(value)
				}
			})

			this.dataCollection = {
				labels: labelsChart,
				datasets: [
					{
						data: dataChart,
						borderColor: '#ffffff',
						backgroundColor: ['#33da6d', '#3300ff', '#ed8e53']
					}
				]
			}
		},
		getOverview() {
			Api.get('dashboard/overview')
				.then(({ data }) => {
					this.overview = data
					this.fillChartData()
				})
				.catch((err) => {
					console.log(err)
				})
		},
		async getLands() {
			this.lands = []
			try {
				const response = await Api.get('lands/findAll')
				if (response.status === 200) {
					this.lands = response.data
					this.initializeMap()
				}
			} catch (e) {
				console.log(e)
			}
        },
		initializeMap() {
			this.map = new Map({
				target: this.$refs['map-root'],
				layers: [
					new TileLayer({
						source: new OSM()
					})
				],
				view: new View({
					zoom: 0,
					center: [0, 0],
					constrainResolution: true
				})
			})

			this.lands.forEach((l) => {
				if (l.lat && l.lon && validator.isLatLong(`${l.lat}, ${l.lon}`)) {
					this.map.addLayer(new VectorLayer({
						id: l.id,
						source: new VectorSource({
							features: [new Feature(new Point(fromLonLat([l.lon, l.lat])))]
						}),
						style: new Style({
							image: new Icon({
								anchor: [0.5, 1],
								scale: 0.1,
								src: require('@/assets/svg/pin.svg')
							})
						})
					}))

					this.map.setView(new View({
						center: fromLonLat([l.lon, l.lat]),
						zoom: 5
					}))
				}
			})
		},
		formatNumber(n) {
			let floor=Math.floor, abs=Math.abs, log=Math.log, round=Math.round, min=Math.min
			let abbrev = ['k', 'M', 'B']

			const rnd = (n, precision) => {
				let prec = 10**precision
				return round(n*prec)/prec
			}

			let base = floor(log(abs(n))/log(1000))
			let suffix = abbrev[min(abbrev.length-1, base-1)]
			base = abbrev.indexOf(suffix) + 1

			return suffix ? rnd(n/1000**base,2)+suffix : '' + n
		}
	},
	computed: {
		...mapState('user', ['user'])
	}
}
</script>
